
.eky-title-sg-proof{
  font-family: 'sg-proof-bold';
  color: black;
}
@media(max-width: 768px){
  .eky-title-sg-proof{
    font-size: 20px !important;
  }
}

.eky-sub-heading-sg-proof, .eky-text-sg-proof{
  font-family: 'sg-proof-reg';
  color: #3f3f3f;
}

.eky-sub-heading-sg-proof{
  font-family: 'sg-proof-bold';
  color: black;
}

.eky-text-sg-proof{
  font-size: 0.9375rem;
  color: #666;
}
@media(max-width: 768px){
  .eky-text-sg-proof{
    font-size: 0.9375rem;
  }
}

@font-face {
  font-family: 'sg-proof-bold';
  src: url('../../assets/fonts/sg-proof-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'sg-proof-reg';
  src: url('../../assets/fonts/sg-proof-reg.ttf') format('truetype');
}