
.eky-title-doordash{
  font-family: 'doordash-bold';
  color: black;
  font-weight: 900;
  line-height: 31.4px;
  letter-spacing: -1.1px;
  margin-bottom: 0.3rem;
}

.eky-sub-heading-doordash, .eky-text-doordash{
  font-family: 'doordash-reg';
  color: #3f3f3f;
}

.eky-sub-heading-doordash{
  font-family: 'doordash-bold';
  color: black;
}

.eky-text-doordash{
  font-size: 14px;
  color: #666;
}
@media(max-width: 768px){
  .eky-text-doordash{
    font-size: 14px;
  }
}

@font-face {
  font-family: 'doordash-bold';
  src: url('../../assets/fonts/doordash-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'doordash-reg';
  src: url('../../assets/fonts/doordash-reg.woff2') format('woff2');
}