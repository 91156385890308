
.eky-title-bevvi{
  font-family: 'bevvi-bold';
  color: #a72b2a;
}
@media(max-width: 768px){
  .eky-title-bevvi{
    font-size: 20px !important;
  }
}

.eky-sub-heading-bevvi, .eky-text-bevvi{
  font-family: 'bevvi-reg';
  color: #3f3f3f;
}

.eky-sub-heading-bevvi{
  font-family: 'bevvi-bold';
  color: #a72b2a;
}

.eky-text-bevvi{
  font-size: 16px;
  color: #666;
}
@media(max-width: 768px){
  .eky-text-bevvi{
    font-size: 14px;
  }
}

@font-face {
  font-family: 'bevvi-bold';
  src: url('../../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'bevvi-reg';
  src: url('../../assets/fonts/bevvi-reg.otf') format('opentype');
}