@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'comfortaa';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll-smooth{
  scroll-behavior: smooth;
}

.showcase-title{
  font-family: Comfortaa;
  font-weight: bold;
  font-size: xx-large;
  text-align: center;
}
.showcase-sub-title{
  font-family: Comfortaa;
  font-weight: bold;
  font-size: x-large;
  text-align: center;
}
.showcase-text{
  font-family: Comfortaa;
  font-size: large;
  text-align: center;
}

.showcase-list{
  list-style: none; 
}
.showcase-list li::before{
  content: "\2022";  
  color: white; 
  display: inline-block; 
  width: 1rem; 
  margin-left: -1em; 
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow {
  border: solid white;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 8px;
}

input {
  color: #333333 !important;
}

.best-buy-list{
  list-style: none; 
}
.best-buy-list li::before{
  content: "\2022";  
  color: #3063D4; 
  font-weight: bold; 
  display: inline-block; 
  width: 1em; 
  margin-left: -1em; 
}
.teknosa-list li::before{
  content: "\2022";  
  color: #f58220; 
  font-weight: bold; 
  display: inline-block; 
  width: 1em; 
  margin-left: -1em; 
}

.best-buy-font-reg{
  font-family: Vazir-reg;
}
.best-buy-font-med{
  font-family: Vazir-med;
}
.best-buy-font-semi{
  font-family: Vazir-semi;
}
.best-buy-font-bold{
  font-family: Vazir-bold;
}

.teknosa-font-reg{
  font-family: Metropolis-Reg;
}
.teknosa-font-semi{
  font-family: Metropolis-Semi;
}
.teknosa-font-bold{
  font-family: Metropolis-Bold;
}
.teknosa-font-med{
  font-family: Metropolis-Medium;
}

@font-face {
  font-family: 'Vazir-reg';
  src: url('../src/pages/best-buy-landing/font/Vazirmatn-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Vazir-med';
  src: url('../src/pages/best-buy-landing/font/Vazirmatn-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Vazir-semi';
  src: url('../src/pages/best-buy-landing/font/Vazirmatn-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Vazir-bold';
  src: url('../src/pages/best-buy-landing/font/Vazirmatn-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Metropolis-Reg';
  src: url('../src/pages/teknosa-landing/font/Metropolis-Regular.otf') format('OpenType');
}
@font-face {
  font-family: 'Metropolis-Semi';
  src: url('../src/pages/teknosa-landing/font/Metropolis-SemiBold.otf') format('OpenType');
}
@font-face {
  font-family: 'Metropolis-Bold';
  src: url('../src/pages/teknosa-landing/font/Metropolis-Bold.otf') format('OpenType');
}
@font-face {
  font-family: 'Metropolis-Medium';
  src: url('../src/pages/teknosa-landing/font/Metropolis-Medium.otf') format('OpenType');
}