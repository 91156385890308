
.intel-evo-i7-background{
  background-image: url('./assets//images/intel-evo-i7-background-image-desktop.png');
  background-repeat: no-repeat;
}

.video-section-background{
  background-image: url('./assets//images/intel-evo-i7-section-two-background-image-desktop.png');
  background-size: cover;
}

.middle-section-background{
  background-image: url('./assets//images/middle-section-background-image.png');
  background-size: cover;
}

.bottom-section-background{
  background-image: url('./assets//images/bottom-section-background-image.png');
  background-size: cover;
}

.intelone-light{
  font-family: 'intelone-light';
}

.intelone-bold{
  font-family: 'intelone-bold';
}

.intelone-medium{
  font-family: 'intelone-medium';
}

.is-active {
  height: auto !important;
  opacity: 1 !important;
  display: block !important;
}

@font-face {
  font-family: 'intelone-light';
  src: url('./assets/fonts/intelone-display-light.woff') format('woff');
}

@font-face {
  font-family: 'intelone-bold';
  src: url('./assets/fonts/intelone-display-bold.woff') format('woff');
}

@font-face {
  font-family: 'intelone-medium';
  src: url('./assets/fonts/IntelOneDisplay-Medium.woff2') format('woff2');
}