
.eky-title-go-puff{
  font-family: 'go-puff-bold';
  color: black;
  font-weight: 900;
  line-height: 31.4px;
  letter-spacing: -1.1px;
  margin-bottom: 0.3rem;
}
@media(max-width: 768px){
  .eky-title-go-puff{
    font-size: 20px !important;
  }
}

.eky-sub-heading-go-puff, .eky-text-go-puff{
  font-family: 'go-puff-reg';
  color: #3f3f3f;
}

.eky-sub-heading-go-puff{
  color: black;
}

.eky-ingredients-and-methods-heading{
  font-family: 'go-puff-bold';
  color: black;
  font-size: 20px;
}

.eky-text-go-puff{
  font-size: 16px;
  color: #666;
}
@media(max-width: 768px){
  .eky-text-go-puff{
    font-size: 14px;
  }
}

@font-face {
  font-family: 'go-puff-bold';
  src: url('../../assets/fonts/go-puff-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'go-puff-reg';
  src: url('../../assets/fonts/go-puff-reg.ttf') format('truetype');
}