
.eky-title-twin-liquors{
  font-family: 'twin-liquors-bold';
  color: black;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0.3rem;
}

.eky-sub-heading-twin-liquors{
  font-family: 'twin-liquors-bold';
  color: black;
}

.eky-text-twin-liquors{
  font-size: 14px;
  color: black;
}
@media(max-width: 768px){
  .eky-text-twin-liquors{
    font-size: 14px;
  }
}

/* @font-face {
  font-family: 'twin-liquors-bold';
  src: url('../../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf') format('true/type');
} */

@font-face {
  font-family: 'twin-liquors-reg';
  src: url('../../assets/fonts/twin-liquors-reg.ttf') format('true/type');
}

@font-face {
  font-family: "twin-liquors-bold";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/playfairdisplay/v30/nuFiD-vYSZviVYUb_rj3ij__anPXDTjYgFE_.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}