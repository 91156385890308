
.eky-title-specs-online{
  font-family: 'specs-online-bold';
  color: black;
}
@media(max-width: 768px){
  .eky-title-specs-online{
    font-size: 20px !important;
  }
}

.eky-sub-heading-specs-online{
  font-family: 'specs-online-bold';
  color: black;
}

.eky-text-specs-online{
  font-family: 'specs-online-reg';
  font-size: 16px;
  color: #4d4d4f;
}
@media(max-width: 768px){
  .eky-text-specs-online{
    font-size: 0.9375rem;
  }
}

@font-face {
  font-family: 'specs-online-bold';
  src: url('../../assets/fonts/Oswald-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'specs-online-reg';
  src: url('../../assets/fonts/OpenSans-Regular.ttf') format('truetype');
}