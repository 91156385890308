
.eky-title{
  font-family: 'Playfair Display';
}

.eky-sub-heading, .eky-text{
  font-family: 'Source Sans Pro';
}

.eky-text{
  font-size: 16px;
  color: #1C252A;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('./assets/fonts/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./assets/fonts/source-sans-pro.woff2') format('woff');
}